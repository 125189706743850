import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { Heading } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../system/Translate';
import { useLines } from './hooks';
import SettingsTable from './SettingsTable';
import SettingsFilters from './SettingsFilters';
import ChangeLineSetting from './ChangeLineSetting';

import './settings.scss';

const ITEMS_PER_PAGE = 250;

export default function Settings() {
    const [lines, getLines] = useLines();
    const [data, setData] = useState([]);

    const { translate } = useContext(TranslateContext);

    const handleFilter = (filters) => {
        if (!filters) {
            initiateData();
        } else {
            const filtered = lines.data.filter((item) => {
                const { code, title, automaticDelays, paxRights, busArrived, businessRegion, concessionOwner } = filters;

                const matchesCode = !code || (item.code && item.code.includes(code.toString()));
                const matchesTitle = !title || (item.title && item.title.includes(title.toString()));
                const matchesConcessionOwner = !concessionOwner || (item.concessionOwner && item.concessionOwner.id === concessionOwner);
                const matchesBusinessRegion = !businessRegion || (item.lineRegion && item.lineRegion.id === businessRegion);
                const matchesDelays = !automaticDelays || automaticDelays === (item.automaticDelays ? 'Yes' : 'No');
                const matchesPaxRights = !paxRights || paxRights === (item.paxRights ? 'Yes' : 'No');
                const matchesBusArrived = !busArrived || busArrived === (item.busArrived ? 'Yes' : 'No');

                return matchesCode && matchesTitle && matchesDelays && matchesPaxRights && matchesConcessionOwner && matchesBusinessRegion && matchesBusArrived;
            });

            const rowsLines = prepareRowsData(filtered);

            setData(rowsLines);
        }
    };

    const prepareRowsData = useCallback((data) => {
        return data.reduce((resultArray, line, index) => {
            const pagesChunkIndex = Math.floor(index / ITEMS_PER_PAGE);

            if (!resultArray[pagesChunkIndex]) {
                resultArray[pagesChunkIndex] = []; // start a new chunk of pages
            }

            resultArray[pagesChunkIndex].push({
                code: line.code,
                title: line.title,
                concessionOwner: line.concessionOwner,
                lineRegion: line.lineRegion,
                autoDelayElement: (
                    <ChangeLineSetting
                        lineCode={line.code}
                        lineId={line.lineId}
                        settingName={'automaticDelays'}
                        settingValue={line.automaticDelays}
                    />
                ),
                paxRightsElement: (
                    <ChangeLineSetting
                        lineCode={line.code}
                        lineId={line.lineId}
                        settingName={'paxRights'}
                        settingValue={line.paxRights}
                    />
                ),
                busArrivedElement: (
                    <ChangeLineSetting
                        lineCode={line.code}
                        lineId={line.lineId}
                        settingName={'busArrived'}
                        settingValue={line.busArrived}
                    />
                ),
                autorebookElement: (
                    <ChangeLineSetting
                        lineCode={line.code}
                        lineId={line.lineId}
                        settingName={'rebookOnScheduleChange'}
                        settingValue={line.rebookOnScheduleChange}
                    />
                )
            });

            return resultArray;
        }, []);
    }, []);

    const preparedData = useMemo(() => {
        if (lines.data !== null) {
            return prepareRowsData([...lines.data]);
        } else {
            return [];
        }
    }, [lines.data]);

    const initiateData = useCallback(() => {
        if (preparedData !== null) {
            setData(preparedData);
        }
    }, [preparedData]);

    useEffect(() => {
        getLines();
    }, [getLines]);

    // prepare table data
    useEffect(() => {
        if (lines.data !== null) {
            initiateData();
        }
    }, [lines.data]);

    return (
        <>
            {lines.pending ? (
                translate('loading')
            ) : (
                <>
                    <Heading size={2}>
                        {translate('settings__origin_line_list')}
                    </Heading>
                    <hr />
                    <div className="settings-page">
                        <SettingsFilters
                            translate={translate}
                            onFilter={handleFilter}
                        />
                        <SettingsTable translate={translate} data={data} />
                    </div>
                </>
            )}
        </>
    );
}
